import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

const calculateTimeLeft = () => {
  const difference = +new Date("2024-06-30T00:00:00") - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      D: Math.floor(difference / (1000 * 60 * 60 * 24)),
      H: Math.floor((difference / (1000 * 60 * 60)) % 24),
      M: Math.floor((difference / 1000 / 60) % 60),
      S: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};

export default ({
  subheading = "",
  heading = "Over 9000 Projects Completed",
  // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  stats = [
    {
      key: "Clients",
      value: "2500+",
    },
    {
      key: "Revenue",
      value: "$100M+",
    },
    {
      key: "Employees",
      value: "150+",
    },
  ]
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>test</Subheading>}
          <Heading>Countdown to Our Big Day</Heading>
          {/* {description && <Description>{description}</Description>} */}
        </HeadingContainer>
        <StatsContainer>
            <Stat>
              <StatValue>{timeLeft.D}</StatValue>
              <StatKey>Days</StatKey>
            </Stat>
            <Stat>
              <StatValue>{timeLeft.H}</StatValue>
              <StatKey>Hours</StatKey>
            </Stat>
            <Stat>
              <StatValue>{timeLeft.M}</StatValue>
              <StatKey>Minutes</StatKey>
            </Stat>
            <Stat>
              <StatValue>{timeLeft.S}</StatValue>
              <StatKey>Seconds</StatKey>
            </Stat>
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
