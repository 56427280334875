import React, { useState , useEffect} from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import  { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Container, ContentWithoutPadding } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 cursor-pointer`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const ModalBackdrop = styled.div`
  ${tw`fixed inset-0 z-50 flex items-center justify-center`}
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = tw(motion.div)`bg-white rounded-lg overflow-hidden max-w-3xl`;

const CloseButton = tw.button`absolute top-0 right-0 m-4 p-2 text-gray-600 hover:text-gray-800`;

const imagesArray = [
  require("../../images/savethedate/CR704096 Large.jpeg"),
  require("../../images/savethedate/CR704096 Large.jpeg"),
  require("../../images/savethedate/CR704109 Large.jpeg"),
  require("../../images/savethedate/CR704208 Large.jpeg"),
  require("../../images/savethedate/CR704227 Large.jpeg"),
  require("../../images/savethedate/CR704289 Large.jpeg"),
  require("../../images/savethedate/CR704444 Large.jpeg"),
  require("../../images/savethedate/CR704561 Large.jpeg"),
  require("../../images/savethedate/CR704646 Large.jpeg"),
  require("../../images/savethedate/CR704685 Large.jpeg"),
  require("../../images/savethedate/CR705071 Large.jpeg"),
  require("../../images/savethedate/CR705098 Large.jpeg"),
  require("../../images/savethedate/CR705277 Large.jpeg"),
  require("../../images/savethedate/CR705324 Large.jpeg"),
  require("../../images/savethedate/CR705335 Large.jpeg"),
  require("../../images/savethedate/CR705354 Large.jpeg"),
  require("../../images/savethedate/CR705534 Large.jpeg"),
  require("../../images/savethedate/CR705573 Large.jpeg"),
  require("../../images/savethedate/CR705612 Large.jpeg"),
  require("../../images/savethedate/CR705772-1 Large.jpeg"),
  require("../../images/savethedate/CR705909 Large.jpeg"),
  require("../../images/savethedate/CR708925 Large.jpeg"),
  require("../../images/savethedate/CR708952 Large.jpeg"),
  require("../../images/savethedate/CR708980 Large.jpeg"),
  require("../../images/savethedate/CR708983 Large.jpeg"),
  require("../../images/savethedate/CR708984 Large.jpeg"),
  require("../../images/savethedate/CR709028 Large.jpeg"),
  require("../../images/savethedate/CR709154 Large.jpeg"),
  require("../../images/savethedate/CR709155 Large.jpeg"),
  require("../../images/savethedate/CR709301 Large.jpeg"),
  require("../../images/savethedate/CR709354 Large.jpeg"),
  require("../../images/savethedate/CR709375 Large.jpeg"),
  require("../../images/savethedate/CR709389 Large.jpeg"),
  require("../../images/savethedate/CR709394 Large.jpeg")
];



const EngagementArray = [
  require("../../images/Engagement/CR707933 (2).jpeg"),
  require("../../images/Engagement/CR707942 (2).jpeg"),
  require("../../images/Engagement/CR707951 (2).jpeg"),
  require("../../images/Engagement/CR707978 (2).jpeg"),
  require("../../images/Engagement/CR707985 (2).jpeg"),
  require("../../images/Engagement/CR708008.jpeg"),
  require("../../images/Engagement/CR708040 (2).jpeg"),
  require("../../images/Engagement/CR708129 (2).jpeg"),
  require("../../images/Engagement/CR708168.jpeg"),
  require("../../images/Engagement/CR708173.jpeg"),
  require("../../images/Engagement/CR708186.jpeg"),
  require("../../images/Engagement/CR708210.jpeg"),
  require("../../images/Engagement/CR708297.jpeg"),
  require("../../images/Engagement/CR708299.jpeg"),
  require("../../images/Engagement/CR708308.jpeg"),
  require("../../images/Engagement/CR708395.jpeg"),
  require("../../images/Engagement/CR708425.jpeg"),
  require("../../images/Engagement/CR708460.jpeg"),
  require("../../images/Engagement/CR708669.jpeg"),
  require("../../images/Engagement/CR708701.jpeg"),
  require("../../images/Engagement/CR708704.jpeg"),
  require("../../images/Engagement/CR708733.jpeg"),
  require("../../images/Engagement/CR708765.jpeg"),
  require("../../images/Engagement/CR708794.jpeg"),
  require("../../images/Engagement/CR708800.jpeg"),
  require("../../images/Engagement/CR708837.jpeg")
];


const Gallery = ({
  heading = "Checkout the Menu",
  tabs = {
    "Save the Date": imagesArray,
    Engagement: EngagementArray
  }
}) => {
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [index, setIndex] = React.useState(-1);
  const [slides, setSlides] = useState([])

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const imgset = tabs[activeTab].map(image => ({
      src: image
    }));
    setSlides(imgset)
  }, [activeTab])
  

console.log(tabs[activeTab]);
  return (
    <Container>
      <ContentWithoutPadding>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((imageSrc, index) => (
              <CardContainer key={index}>
                <Card className="group" onClick={() => setIndex(index)}>
                  <CardImageContainer imageSrc={imageSrc} />
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithoutPadding>
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
      {showModal && (
        <ModalBackdrop>
          <ModalContent
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CloseButton onClick={closeModal}>Close</CloseButton>
            <img src={selectedImage} alt="Full size" className="w-full" />
          </ModalContent>
        </ModalBackdrop>
      )}
    </Container>
  );
};

export default Gallery;
