import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const CardDiv = tw.div`flex justify-center`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none mr-4`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;


const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null,
  ref
}) => {

  const cards = [
    {
      imageSrc: "https://content.jdmagicbox.com/comp/ernakulam/s9/0484px484.x484.190823103027.t9s9/catalogue/rajiv-gandhi-sahakarana-auditorium-manalimukku-sivagiri-road-ernakulam-auditoriums-EHyQKE7dPY.jpg",
      title: "Wedding Ceremony",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Rajiv Gandhi Sahakarana Auditorium, Edathala Aluva",
      time: "Muhoortham 10:40 AM to 11:20 AM",
      mapDirection: "https://maps.app.goo.gl/QiPcfdHHbzX9ceTz5"
    },
  
    {
      imageSrc: "https://content3.jdmagicbox.com/comp/ernakulam/a5/0484px484.x484.171224024040.e9a5/catalogue/st-joseph-parish-hall-kottamam-ernakulam-glxifx0x30.jpg",
      title: "Wedding Reception",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "St.Joseph Parish Hall, Kottamam (Kalady - Malayatoor Road)",
      time: "Reception Timing: 5 PM to 9 PM",
      mapDirection: "https://maps.app.goo.gl/4fwNSzNxFH9sAjQ18"
    },
  ]

  return (
    <Container ref={ref}>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <Content>
        <HeadingWithControl>
        </HeadingWithControl>
        {/* <CardSlider> */}
          <CardDiv>
          {cards.map((card, index) => (
            <Card key={index} onClick={() => window.open(card.mapDirection)            }>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
               
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.time}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                {/* <Description>{card.description}</Description> */}
              </TextInfo>
              <PrimaryButton>Get Directions</PrimaryButton>
            </Card>
          ))}
          </CardDiv>
        {/* </CardSlider> */}
      </Content>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
