import React, { useEffect } from "react";
import tw from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


const AnimatedSection = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation
      once: true, // Only once animation plays
      easing: "ease-out", // Easing option
    });
  }, []);

  return (
    <StyledDiv className="App">
      <div data-aos="fade-left">{children}</div>
    </StyledDiv>
  );
};
export default AnimatedSection;
